import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Page404 from "./404.js"

//importación de componentes
import MainLayout from "../components/Layouts/MainLayout"
import FormContainer from "../components/FormContainer"
import FormOculto from "./formOculto.js"
import useContentful from "../utils/useContentful.jsx"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  /* margin: 20px 0px; */
  padding: 3% 9% 10% 9%;
`

const SectionTitle = styled.div`
  display: inline-block !important;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 150.3%;
  letter-spacing: 0.055em;
  color: #262626;
  @media (max-width: 700px) {
    font-size: 20px;
  }
`

const SectionParagraph = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 136.8%;
  letter-spacing: 0.1em;
  color: #767b8a;
  font-size: ${props => props.fontSize || "16px"};
  @media (min-width: 700px) {
    font-size: 16px;
  }
  @media (min-width: 1050px) {
    font-size: 18px;
  }
  @media (max-width: 900px) {
    letter-spacing: 0;
    margin-top: 1.5rem;
  }
`

const GeneralEntryId = "7oLm71CHpfhQxDLFPtts1F"

export default function Form() {

  const { data: general, fetched } = useContentful({ id: GeneralEntryId });



  return (

<>
{fetched ?

// general.fields.activePostula ? <FormOculto></FormOculto>: <Page404></Page404> : <p>Loading</p>}

false ? <FormOculto></FormOculto>: <Page404></Page404> : <p>Loading</p>}

</>


   
  )
}

